import React from "react";
import { Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const CircularProgressWithLabel = ({ value, label }) => {
  return (
    <>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        {label}:
      </Typography>
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          variant="determinate"
          value={(value / 10) * 100}
          size={80}
          thickness={4}
          color="primary"
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
            fontWeight={"bold"}
          >
            {`${value}/10`}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default CircularProgressWithLabel;
