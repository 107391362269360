import React from 'react';
import { Box, Pagination as MuiPagination } from '@mui/material';

function Pagination({ page, setPage, totalPages }) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
      <MuiPagination
        count={totalPages}
        page={page}
        onChange={(_, value) => setPage(value)}
        color="primary"
      />
    </Box>
  );
}

export default Pagination;