import React, { useState } from 'react';
import { Grid, Typography, Box, Container } from '@mui/material';
import { Search, Send } from '@mui/icons-material';
import {
  TextField,
  Card,
  StatusBadge,
  CopyButton,
  DiffView,
} from './index';

const ComponentShowcase = () => {
  const [textValue, setTextValue] = useState('');
  const [oldText, setOldText] = useState('The quick brown fox jumps over the lazy dog.');
  const [newText, setNewText] = useState('The quick brown fox leaps over the sleeping dog.');

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Component Library Showcase
      </Typography>

      <Grid container spacing={4}>
        {/* DiffView Section */}
        <Grid item xs={12}>
          <Card title="DiffView - Text Comparison">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Original Text"
                  value={oldText}
                  onChange={(e) => setOldText(e.target.value)}
                  multiline
                  rows={4}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Modified Text"
                  value={newText}
                  onChange={(e) => setNewText(e.target.value)}
                  multiline
                  rows={4}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Diff Result:
                </Typography>
                <DiffView oldText={oldText} newText={newText} />
              </Grid>
            </Grid>
          </Card>
        </Grid>

        {/* Text Fields Section */}
        <Grid item xs={12}>
          <Card title="Text Fields">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Standard TextField"
                  value={textValue}
                  onChange={(e) => setTextValue(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="With Adornments"
                  startAdornment={<Search />}
                  endAdornment={<Send />}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Multiline TextField"
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="With Error"
                  error="This field has an error"
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>

        {/* Status Badges Section */}
        <Grid item xs={12}>
          <Card title="Status Badges">
            <Grid container spacing={2}>
              <Grid item>
                <StatusBadge status="Success" color="success" />
              </Grid>
              <Grid item>
                <StatusBadge status="Error" color="error" />
              </Grid>
              <Grid item>
                <StatusBadge status="Warning" color="warning" />
              </Grid>
              <Grid item>
                <StatusBadge status="Info" color="info" />
              </Grid>
            </Grid>
          </Card>
        </Grid>

        {/* Utility Components Section */}
        <Grid item xs={12}>
          <Card title="Utility Components">
            <Grid container spacing={2}>
              <Grid item>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body1" sx={{ mr: 1 }}>
                    Copy Text:
                  </Typography>
                  <CopyButton text="Text to copy" />
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ComponentShowcase;
