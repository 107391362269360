import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { styled, keyframes } from "@mui/system";

// Keyframes for the animation
const redAnimation = keyframes`
  0% { stroke: #fff; }
  100% { stroke: red; }
`;

const greenAnimation = keyframes`
  0% { stroke: #fff; }
  100% { stroke: green; }
`;

const greyAnimation = keyframes`
  0% { stroke: #fff; }
  100% { stroke: grey; }
`;

// Styled component for the circle
const AnimatedCircle = styled("circle")(({ probability }) => ({
  animation:
    probability > 5
      ? `${greenAnimation} 1s forwards`
      : probability < 5
      ? `${redAnimation} 1s forwards`
      : `${greyAnimation} 1s forwards`,
  stroke: probability === 5 ? 'grey' : 'none',
}));

const OverallPreferenceCircle = ({ probabilityOfYes }) => {
  const [probability, setProbability] = useState(null);
  useEffect(() => {
    // Simulate delay for animation when component loads
    setTimeout(() => {
      setProbability(probabilityOfYes);
    }, 50);
  }, [probabilityOfYes]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // justifyContent: "center",
      }}
    >
      <Typography variant="h6">Overall Preference:</Typography>
      <svg width="100" height="100" viewBox="0 0 120 120">
        <circle
          cx="60"
          cy="60"
          r="50"
          fill="none"
          stroke="#e0e0e0"
          strokeWidth="8"
        />
        <AnimatedCircle
          cx="60"
          cy="60"
          r="50"
          fill="none"
          strokeWidth="8"
          probability={probability}
          strokeDasharray="314" // Circumference of the circle
          strokeDashoffset="0"
        />
        <text
          x="60"
          y="65"
          textAnchor="middle"
          fontSize="21"
          fill={probability > 5 ? "green" : probability < 5 ? "red" : "grey"}
          //   fontWeight="bold"
        >
          {probability > 5 ? "Yes" : probability < 5 ? "No" : "Neutral"}
        </text>
      </svg>
    </Box>
  );
};

export default OverallPreferenceCircle;
