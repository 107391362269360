import React from 'react';
import { Box, TextField, Stack, Button, Typography } from '@mui/material';

function EventCommentEditor({
  event,
  userId,
  editingCommentId,
  setEditingCommentId,
  commentText,
  setCommentText,
  updateEventFeedback
}) {


  const handleCommentSave = async (event) => {
    try {
      await updateEventFeedback.mutateAsync({
        eventId: event.event_id,
        data: {
          user_id: userId,
          comment: commentText?.trim() || ''
        }
      });
  
      setEditingCommentId(null);
      setCommentText('');
    } catch (error) {
      console.error('Failed to save comment:', error);
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      {editingCommentId === event.event_id ? (
        <Box sx={{ mt: 1 }}>
          <TextField
            fullWidth
            multiline
            rows={3}
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            size="small"
          />
          <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
            <Button
              variant="contained"
              size="small"
              onClick={() => handleCommentSave(event)}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={() => setEditingCommentId(null)}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      ) : (
        <>
          {event.feedback?.comments && Object.entries(event.feedback.comments).map(([userId, comment]) => (
            <Typography
              key={userId}
              variant="body2"
              sx={{
                whiteSpace: 'pre-wrap',
                fontStyle: 'italic',
                borderLeft: '2px solid grey',
                color: 'rgba(255, 255, 255, 0.8)',
                p: 1,
                mb: 1
              }}
            >
              {comment}
            </Typography>
          ))}
        </>
      )}
    </Box>
  );
}

export default EventCommentEditor; 