import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Chip,
  Typography,
  Pagination,
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Alert
} from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import { useInputs } from '../hooks/useQueries';
import { useLoadingWithCountdown } from '../utils/loadingUtils';
import { LoadingOverlay } from './common/LoadingOverlay';

const statusColors = {
  'unprocessed': 'secondary',
  'in_progress': 'primary',
  'upgraded': 'info',
  'processed': 'success',
  'skipped': 'warning',
  'failed': 'error'
};

const suitabilityColors = {
  '1': 'error',
  '2': 'warning',
  '3': 'info',
  '4': 'success',
  '5': 'success'
};

const suitabilityLabels = {
  '1': 'unsuitable',
  '2': 'questionable',
  '3': 'acceptable',
  '4': 'reliable',
  '5': 'optimal'
};

function InputList() {
  const [page, setPage] = React.useState(1);
  const [selectedQueries, setSelectedQueries] = React.useState([]);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { loading, countdown, startLoading, stopLoading } = useLoadingWithCountdown(true);
  const { data, error, isLoading } = useInputs(page);

  React.useEffect(() => {
    if (isLoading) {
      startLoading();
    } else {
      stopLoading();
    }
  }, [isLoading]);

  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const showRelatedData = (inputId, queries) => {
    setSelectedQueries(queries);
    setDialogOpen(true);
  };

  const formatDate = (isoString) => {
    if (!isoString) return '-';
    try {
      const date = new Date(isoString);
      return new Intl.DateTimeFormat('default', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short'
      }).format(date);
    } catch (e) {
      return isoString;
    }
  };

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <>
      <TableContainer component={Paper} sx={{ position: 'relative' }}>
        {loading && <LoadingOverlay countdown={countdown} />}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ verticalAlign: 'middle' }}>Actions</TableCell>
              <TableCell sx={{ verticalAlign: 'middle' }}>ID</TableCell>
              <TableCell sx={{ verticalAlign: 'middle' }}>
                Type/
                <br /> 
                Suitability
                {/* <br />   
                Category
                <br />
                Status
                <br />
                Suitability */}
              </TableCell>
              <TableCell sx={{ verticalAlign: 'middle', maxWidth: '50%' }}>Text</TableCell>
              <TableCell sx={{ verticalAlign: 'middle' }}>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.inputs.map((input) => {
              const queries = input.queries || [];
              const processedQueries = queries.filter(q => q.status === 'processed').length;
              const totalQueries = queries.length;

              return (
                <TableRow key={input.input_id}>
                  <TableCell sx={{ verticalAlign: 'middle' }}>
                    <IconButton
                      size="small"
                      onClick={() => showRelatedData(input.input_id, queries)}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <span className="fas fa-expand" />
                        <Box sx={{ ml: 1 }}>
                          {processedQueries > 0 && (
                            <Chip
                              label={processedQueries}
                              size="small"
                              color="success"
                              sx={{ mr: 0.5 }}
                            />
                          )}
                          {(totalQueries - processedQueries) > 0 && (
                            <Chip
                              label={totalQueries - processedQueries}
                              size="small"
                              color="default"
                            />
                          )}
                        </Box>
                      </Box>
                    </IconButton>
                  </TableCell>
                  <TableCell sx={{ verticalAlign: 'middle' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton
                        size="small"
                        onClick={() => handleCopy(input.input_id)}
                      >
                        <ContentCopy fontSize="small" />
                      </IconButton>
                      <Typography sx={{ ml: 1 }}>
                        {input.input_id.substr(0, 8)}...
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ verticalAlign: 'middle' }}>
                    {input.type || '-'}<br />
                    <Chip
                      label={suitabilityLabels.hasOwnProperty(input.suitability_score) ? suitabilityLabels[input.suitability_score] : input.suitability_score || 'N.A.'}
                      color={suitabilityColors.hasOwnProperty(input.suitability_score) ? suitabilityColors[input.suitability_score] : 'default'}
                      size="small"
                    />
                    <br />
                    {/* {input.category || '-'}<br />
                    <Chip
                      label={input.status}
                      color={statusColors[input.status]}
                      size="small"
                    /><br /> */}
                  </TableCell>
                  <TableCell sx={{ verticalAlign: 'middle' }}>
                    {input.type == 'google_trend' ? (
                      <>
                        <b>Google Trends:</b> &nbsp;
                        <Typography component="span" sx={{ fontSize: '1.25rem' }}>
                          {input.note}
                        </Typography>
                        <br />
                        <b>News founded to build queries:</b>
                        {input.text}
                      </>
                    ) : (input.type == 'twitter_trend' ? (
                      <>
                        <b>Twitter Trends:</b> &nbsp;
                        <Typography component="span" sx={{ fontSize: '1.25rem' }}>
                          {input.note}
                        </Typography>
                        <br />
                        <b>top 20 tweets summary:</b><br />
                        {input.text}
                      </>
                    ) : (
                      input.text || '-'
                    ))}
                  </TableCell>
                  <TableCell sx={{ verticalAlign: 'middle' }}>{formatDate(input.created_at)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Pagination
          count={data?.pages || 0}
          page={page}
          onChange={(_, value) => setPage(value)}
          color="primary"
        />
      </Box>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Related Queries</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ verticalAlign: 'middle' }}>ID</TableCell>
                  <TableCell sx={{ verticalAlign: 'middle' }}>Category</TableCell>
                  <TableCell sx={{ verticalAlign: 'middle' }}>Status</TableCell>
                  <TableCell sx={{ verticalAlign: 'middle' }}>Text</TableCell>
                  <TableCell sx={{ verticalAlign: 'middle' }}>Events</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedQueries.map((query) => (
                  <TableRow key={query.query_id}>
                    <TableCell sx={{ verticalAlign: 'middle' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                          size="small"
                          onClick={() => handleCopy(query.query_id)}
                        >
                          <ContentCopy fontSize="small" />
                        </IconButton>
                        <Typography sx={{ ml: 1 }}>
                          {query.query_id.substr(0, 8)}...
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ verticalAlign: 'middle' }}>{query.category || '-'}</TableCell>
                    <TableCell sx={{ verticalAlign: 'middle' }}>
                      <Chip
                        label={query.status}
                        color={statusColors[query.status]}
                        size="small"
                      />
                    </TableCell>
                    <TableCell sx={{ verticalAlign: 'middle' }}>{query.text || '-'}</TableCell>
                    <TableCell sx={{ verticalAlign: 'middle' }}>
                      <Chip
                        label={query.events?.length || 0}
                        size="small"
                        color="default"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default InputList;
