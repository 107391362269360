import axios from 'axios';

const api = axios.create({
  baseURL: '/api'
});

const mockMode = false; // Set this to true to enable mock mode
const mockDelay = 10;

const perPage = 25;

import mockEvents from './mockEvents.json';

export const fetchEvents = async (page = 1, filters = {}) => {
  if (mockMode) {
    console.info(`GET /events: {page: ${page}, filters: ${JSON.stringify(filters)}}`);
    // Simulate filtering logic for mock data
    let filteredEvents = mockEvents;
    if (filters.reactions && filters.reactions.length > 0) {
      filteredEvents = filteredEvents.filter(event =>
        filters.reactions.some(reaction => event.feedback?.rating?.[reaction]?.length > 0)
      );
    }
    if (filters.issues && filters.issues.length > 0) {
      filteredEvents = filteredEvents.filter(event =>
        filters.issues.some(issue => event.feedback?.issues?.[issue]?.length > 0)
      );
    }
    if (filters.changes && filters.changes.length > 0) {
      filteredEvents = filteredEvents.filter(event => event.changes);
    }
    if (filters.has_comments) {
      filteredEvents = filteredEvents.filter(event => Object.keys(event.feedback?.comments || {}).length > 0);
    }
    if (filters.has_reasoning) {
      filteredEvents = filteredEvents.filter(event => event.event_metadata?.reasoning);
    }
    if (filters.time_frame) {
      const now = new Date();
      filteredEvents = filteredEvents.filter(event => {
        const eventDate = new Date(event.targeted_date);
        return filters.time_frame === 'past' ? eventDate < now : eventDate > now;
      });
    }
    await new Promise(resolve => setTimeout(resolve, mockDelay)); // Implement mock delay
    return {
      current_page: page,
      events: filteredEvents.slice((page - 1) * perPage, page * perPage),
      pages: Math.ceil(filteredEvents.length / perPage),
      total: filteredEvents.length
    };
  } else {
    const params = new URLSearchParams();
    params.append('page', page.toString());

    if (filters.reactions && filters.reactions.length > 0) {
      filters.reactions.forEach(reaction => params.append('reactions[]', reaction));
    }

    if (filters.issues && filters.issues.length > 0) {
      filters.issues.forEach(issue => params.append('issues[]', issue));
    }

    if (filters.changes && filters.changes.length > 0) {
      filters.changes.forEach(change => params.append('changes[]', change));
    }

    if (filters.has_comments) {
      params.append('has_comment', filters.has_comments);
    }

    if (filters.has_reasoning) {
      params.append('has_reasoning', filters.has_reasoning);
    }

    if (filters.time_frame) {
      params.append('time_frame', filters.time_frame);
    }

    const response = await api.get(`/events?${params}`);
    return response.data;
  }
};

export const updateEventFeedback = async (eventId, data) => {
  if (mockMode) {
    console.info(`POST /events/${eventId}/feedback: ${JSON.stringify(data)}`);
    const eventIndex = mockEvents.findIndex(event => event.event_id === eventId);
    if (eventIndex !== -1) {
      const event = mockEvents[eventIndex];
      const { feedback } = event;
      const userId = data.user_id;

      // Update Rating
      if (data.rating !== undefined) {
        // Remove existing user rating
        Object.keys(feedback.rating).forEach(ratingKey => {
          feedback.rating[ratingKey] = feedback.rating[ratingKey].filter(id => id !== userId);
          if (feedback.rating[ratingKey].length === 0) {
            delete feedback.rating[ratingKey];
          }
        });
        // Add new rating
        if (feedback.rating[data.rating]) {
          feedback.rating[data.rating].push(userId);
        } else {
          feedback.rating[data.rating] = [userId];
        }
      }

      // Update Issues
      if (data.issues) {
        data.issues.forEach(issue => {
          if (!feedback.issues[issue]) {
            feedback.issues[issue] = [];
          }
          if (!feedback.issues[issue].includes(userId)) {
            feedback.issues[issue].push(userId);
          }
        });
        // Remove user from issues no longer reported
        Object.keys(feedback.issues).forEach(issueKey => {
          if (!data.issues.includes(issueKey)) {
            feedback.issues[issueKey] = feedback.issues[issueKey].filter(id => id !== userId);
            if (feedback.issues[issueKey].length === 0) {
              delete feedback.issues[issueKey];
            }
          }
        });
      }

      // Update Comment
      if (data.comment !== undefined) {
        if (data.comment) {
          feedback.comments[userId] = data.comment;
        } else {
          delete feedback.comments[userId];
        }
      }

      // Update average rating
      const allRatings = Object.entries(feedback.rating).flatMap(([rating, users]) =>
          Array(users.length).fill(parseInt(rating))
      );
      feedback.rating_average = allRatings.length ? (allRatings.reduce((a, b) => a + b, 0) / allRatings.length) : null;

      mockEvents[eventIndex].feedback = feedback;
      await new Promise(resolve => setTimeout(resolve, mockDelay)); // Implement mock delay
      return mockEvents[eventIndex];
    }
    throw new Error('Event not found');
  } else {
    const response = await api.post(`/events/${eventId}/feedback`, data);
    return response.data;
  }
};

export const fetchInputs = async (page = 1) => {
  // if (mockMode) {
  //   // Mock inputs data if needed
  //   return { inputs: [], total: 0 };
  // } else {
    const response = await api.get(`/inputs?page=${page}`);
    return response.data;
  // }
};
