import React from "react";
import {
  Box,
  Typography,
  Divider,
  Grid2,
  Chip,
  TableRow,
  TableCell,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Link from "@mui/material/Link";
import OverallPreferenceCircle from "./OverollPreferenceCircle";
import CircularProgressWithLabel from "./CircularProgressWithLabel";

const ItemReasoning = ({ reasoning }) => {
  return (
    <>
      <TableRow key={`${reasoning.current_events_impact}-row`}>
        <TableCell colSpan={5} sx={{ borderLeft: "2px dashed" }}>
        <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 16,
              textAlign: 'center',
              marginX: 16
            }}
          >
             <Typography variant="h6">{reasoning.final_estimation.justification}</Typography>
            </Box>
            <Divider sx={{ marginY: 2 }} />
          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              gap: 16,
            }}
          >
            <Box sx={{ width: "40%", textAlign: 'center',
               }}>
              <Typography variant="h6">Main Positive Point:</Typography>
              <Typography variant="body2" color="success">
                {reasoning.comparison.strongest_yes_point}
              </Typography>
            </Box>
            <Box sx={{ width: "20%" }}>
              <OverallPreferenceCircle
                probabilityOfYes={reasoning.final_estimation.probability_of_yes}
              />
            </Box>
            <Box sx={{ width: "40%" ,textAlign: 'center',}}>
              <Typography variant="h6">Main Negative Point:</Typography>
              <Typography variant="body2" color="error">
                {reasoning.comparison.strongest_no_point}
              </Typography>
            </Box>
          </Box>

          <Divider sx={{ marginY: 2 }} />
          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              alignItems: 'center',
              gap: 16,
            }}
          >
            <Box sx={{ width: "33%", display: "flex", alignItems:'center',flexDirection: 'column' }}>
                <CircularProgressWithLabel
                 value={reasoning.final_estimation.popularity_rating}
                 label={"Popularity"}
                 />
            </Box>
            <Box sx={{ width: "33%", display: "flex", alignItems:'center',flexDirection: 'column' }}>
                <CircularProgressWithLabel
                 value={reasoning.final_estimation.controversy_rating}
                 label={"Controversy"}
                 />
            </Box>
            <Box sx={{ width: "33%", display: "flex", alignItems:'center',flexDirection: 'column' }}>
            <CircularProgressWithLabel
                 value={reasoning.final_estimation.probability_of_yes}
                 label={"Probability"}
                 />
            </Box>
          </Box>

          <Divider sx={{ marginY: 2 }} />

          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              gap: "16px",
            }}
          >
            <Box sx={{ width: "47%" }}>
              <Typography
                variant="h6"
                color="success"
                sx={{ fontWeight: "bold" }}
              >
                Supporting Arguments:
              </Typography>
              {reasoning.yes_reasoning_analysis.reasons.map((arg, index) => (
                <Box
                  key={index}
                  sx={{
                    marginY: 1,
                    padding: 2,
                    borderRadius: 2,
                    backgroundColor: "#66bb6a30",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleOutlineIcon
                      sx={{ color: "green", marginRight: "16px" }}
                    />
                    <Grid2 item xs>
                      <Typography variant="body2">{arg.point}</Typography>
                      <Typography variant="caption" color="text.primary">
                        Confidence: {arg.strength}/10
                      </Typography>
                      <br />
                      {arg.sources && arg.sources.length > 0 && (
                        <Link
                          href={arg.sources[0]}
                          target="_blank"
                          rel="noopener"
                          variant="caption"
                        >
                          Source
                        </Link>
                      )}
                    </Grid2>
                  </Box>
                </Box>
              ))}
            </Box>
            <Box sx={{ width: "47%" }}>
              <Typography
                variant="h6"
                color="error"
                sx={{ fontWeight: "bold" }}
              >
                Counter Arguments:
              </Typography>
              {reasoning.no_reasoning_analysis.reasons.map((arg, index) => (
                <Box
                  key={index}
                  sx={{
                    marginY: 1,
                    padding: 2,
                    borderRadius: 2,
                    backgroundColor: "#f4433630",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <ErrorOutlineIcon
                      sx={{ color: "red", marginRight: "16px" }}
                    />
                    <Grid2 item xs>
                      <Typography variant="body2">{arg.point}</Typography>
                      <Typography variant="caption" color="text.secondary">
                        Confidence: {arg.strength}/10
                      </Typography>
                      <br />
                      {arg.sources && arg.sources.length > 0 && (
                        <Link
                          href={arg.sources[0]}
                          target="_blank"
                          rel="noopener"
                          variant="caption"
                        >
                          Source
                        </Link>
                      )}
                    </Grid2>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ItemReasoning;
