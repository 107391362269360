export function formatDate(timestamp) {
  if (!timestamp) return '-';
  if (typeof timestamp === 'string' && timestamp.includes('T')) {
    return timestamp.split('T')[0];
  }
  try {
    const date = new Date(timestamp * 1000);
    return new Intl.DateTimeFormat('default', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short'
    }).format(date);
  } catch (e) {
    return timestamp;
  }
} 