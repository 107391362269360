import React from "react";
import {
  TableRow,
  TableCell,
  Stack,
  Button,
  Typography,
  Checkbox,
} from "@mui/material";
import CopyButton from "../common/CopyButton";
import IconButton from "@mui/material/IconButton";
import BalanceIcon from "@mui/icons-material/Balance";
import GitBranchIcon from "@mui/icons-material/AccountTree";
import ItemChanges from "../common/ItemChanges";
import EventCommentEditor from "./EventCommentEditor";
import { formatDate } from "../../utils/dateUtils";
import ItemReasoning from "../common/ItemReasoning";

const reactions = [
  { emoji: "😍", rating: 5 },
  { emoji: "🙂", rating: 4 },
  { emoji: "😐", rating: 3 },
  { emoji: "😕", rating: 2 },
  { emoji: "😡", rating: 1 },
];

const issues = [
  { key: "need_improve_date", label: "need improve date" },
  { key: "hard_to_verify", label: "hard to verify" },
  { key: "too_broad", label: "too broad" },
  { key: "irrelevant", label: "irrelevant" },
  { key: "highly_predictable", label: "highly predictable" },
];

function EventRow({
  event,
  userId,
  expandedChanges,
  setExpandedChanges,
  setLoadingEventIds,
  editingCommentId,
  setEditingCommentId,
  commentText,
  setCommentText,
  updateEventFeedback,
  loadingEventIds,
  selected,
  onSelect,
  setExpandedReasoning,
  expandedReasoning,
}) {
  const handleReactionClick = async (event, rating) => {
    try {
      setLoadingEventIds((prev) => new Set([...prev, event.event_id]));
      await updateEventFeedback.mutateAsync({
        eventId: event.event_id,
        data: {
          user_id: userId,
          rating,
        },
      });
    } catch (error) {
      console.error("Failed to update reaction:", error);
    } finally {
      setLoadingEventIds((prev) => {
        const next = new Set(prev);
        next.delete(event.event_id);
        return next;
      });
    }
  };

  const handleIssueClick = async (event, issueKey) => {
    try {
      setLoadingEventIds((prev) => new Set([...prev, event.event_id]));
      const currentIssues = event.feedback?.issues || {};
      const updatedIssuesList = Object.keys(currentIssues).reduce(
        (acc, key) => {
          const userVoted = currentIssues[key]?.includes(userId);
          if (userVoted && key !== issueKey) {
            acc.push(key);
          }
          return acc;
        },
        []
      );

      if (!currentIssues[issueKey]?.includes(userId)) {
        updatedIssuesList.push(issueKey);
      }

      await updateEventFeedback.mutateAsync({
        eventId: event.event_id,
        data: {
          user_id: userId,
          issues: updatedIssuesList,
        },
      });
    } catch (error) {
      console.error("Failed to update issues:", error);
    } finally {
      setLoadingEventIds((prev) => {
        const next = new Set(prev);
        next.delete(event.event_id);
        return next;
      });
    }
  };

  const toggleChanges = (eventId) => {
    setExpandedChanges((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(eventId)) {
        newSet.delete(eventId);
      } else {
        newSet.add(eventId);
      }
      return newSet;
    });
  };

  const toggleReasoning = (eventId) => {
    setExpandedReasoning((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(eventId)) {
        newSet.delete(eventId);
      } else {
        newSet.add(eventId);
      }
      return newSet;
    });
  };

  const highRating = event.feedback?.rating?.[5]?.length > 0;
  const lowRating =
    event.feedback?.rating?.[2]?.length > 0 ||
    event.feedback?.ratings?.[1]?.length > 0;

  return (
    <React.Fragment>
      <TableRow
        hover
        selected={selected}
        className={highRating ? "row-loved" : lowRating ? "row-negative" : ""}
      >
        <TableCell
          className={lowRating ? "low-rating" : ""}
          sx={{ verticalAlign: "top" }}
        >
          <Stack spacing={1}>
            <CopyButton text={event.event_id} />
            <Checkbox
              checked={selected}
              onChange={(event) => onSelect(event.target.checked)}
            />
            <IconButton
              size="small"
              disabled={!event.changes || !event.changes.length}
              onClick={() => toggleChanges(event.event_id)}
              sx={{
                opacity: event.changes ? 1 : 0.3,
                color: expandedChanges.has(event.event_id)
                  ? "primary.main"
                  : "inherit",
              }}
            >
              <GitBranchIcon />
            </IconButton>
            <IconButton
              size="small"
              disabled={!!!event?.event_metadata?.reasoning}
              onClick={() => toggleReasoning(event.event_id)}
              sx={{
                opacity: !!event?.event_metadata?.reasoning ? 1 : 0.3,
                color: expandedReasoning.has(event.event_id)
                  ? "primary.main"
                  : "inherit",
              }}
            >
              <BalanceIcon />
            </IconButton>
          </Stack>
        </TableCell>
        {/* Title, Rating */}
        <TableCell sx={{ verticalAlign: "top" }}>
          <Typography variant="body2">{event.title || "-"}</Typography>
          <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
            {reactions.map(({ emoji, rating }) => (
              <Button
                key={rating}
                variant={
                  event.feedback?.rating?.[rating] ? "contained" : "outlined"
                }
                color={
                  event.feedback?.rating?.[rating]?.includes(userId)
                    ? "primary"
                    : event.feedback?.ratings?.[rating]?.length > 0
                    ? "secondary"
                    : "inherit"
                }
                size="small"
                onClick={() => handleReactionClick(event, rating)}
                disabled={loadingEventIds.has(event.event_id)}
              >
                {emoji} ({event.feedback?.rating?.[rating]?.length || 0})
              </Button>
            ))}
          </Stack>
        </TableCell>
        {/* Description, Issues,Comment */}
        <TableCell sx={{ verticalAlign: "top" }}>
          <Typography variant="body2">{event.description || "-"}</Typography>
          <Stack direction="row" sx={{ mt: 1, flexWrap: "wrap", gap: 1 }}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                setEditingCommentId(event.event_id);
                setCommentText(event.feedback?.comments?.[userId] || "");
              }}
              disabled={loadingEventIds.has(event.event_id)}
            >
              📝
            </Button>
            {issues.map(({ key, label }) => (
              <Button
                key={key}
                variant={
                  event.feedback?.issues?.hasOwnProperty(key)
                    ? "contained"
                    : "outlined"
                }
                color={
                  event.feedback?.issues?.[key]?.includes(userId)
                    ? "primary"
                    : event.feedback?.issues?.[key]?.length > 0
                    ? "secondary"
                    : "inherit"
                }
                size="small"
                onClick={() => handleIssueClick(event, key)}
                disabled={loadingEventIds.has(event.event_id)}
              >
                {label} ({event.feedback?.issues?.[key]?.length || 0})
              </Button>
            ))}
          </Stack>
          <EventCommentEditor
            event={event}
            userId={userId}
            editingCommentId={editingCommentId}
            setEditingCommentId={setEditingCommentId}
            commentText={commentText}
            setCommentText={setCommentText}
            updateEventFeedback={updateEventFeedback}
          />
        </TableCell>
        <TableCell sx={{ verticalAlign: "top" }}>
          {event.country || "-"}
        </TableCell>
        <TableCell sx={{ verticalAlign: "top" }}>
          {formatDate(event.created_at)}
        </TableCell>
      </TableRow>
      {event.changes && expandedChanges.has(event.event_id) && (
        <ItemChanges changes={event.changes} />
      )}
      {event.event_metadata.reasoning &&
        expandedReasoning.has(event.event_id) && (
          <ItemReasoning reasoning={event.event_metadata.reasoning} />
        )}
    </React.Fragment>
  );
}

export default EventRow;
