import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import DiffView from './DiffView';

function ItemChanges({ changes }) {
  
  const prompts = [
    { key: 'EventImproveDate', label: 'Event: Date Improvement' },
    { key: 'EventImproveVerification', label: 'Event: Verification Improvement' },
  ];

  return (
    <>
      {changes.map((change, id) => (
        <React.Fragment key={`${change.id}-fragment`}>
          <TableRow key={`${change.id}-row1`} sx={{ borderLeft: '2px dashed' }}>
            <TableCell rowSpan={2} sx={{ writingMode: 'vertical-rl', textOrientation: 'mixed', textAlign: 'center' }}>
              {changes.length - id}) {prompts.find(p => p.key === change.prompt_name)?.label}
            </TableCell>
            <TableCell sx={{ verticalAlign: 'top' }}>
              <DiffView oldText={change.title_old} newText={change.title_new} />
            </TableCell>
            <TableCell sx={{ verticalAlign: 'top' }}>
              <DiffView oldText={change.description_old} newText={change.description_new} />
            </TableCell>
            <TableCell></TableCell>
            <TableCell sx={{ verticalAlign: 'top' }}>
              <DiffView oldText={change.date_old} newText={change.date_new} />
            </TableCell>
          </TableRow>
          <TableRow key={`${change.id}-row2`} sx={{ borderLeft: '2px dashed' }}>
            <TableCell colSpan={4}>
              {change.scratchpad.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  {index < change.scratchpad.split('\n').length - 1 && <br />}
                </React.Fragment>
              ))}
            </TableCell>
          </TableRow>
        </React.Fragment>
      ))}
    </>
  );
}

export default ItemChanges;