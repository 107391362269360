import React from 'react';
import { Box, Stack, Button, Divider, Typography } from '@mui/material';

const reactions = [
  { emoji: '😍', rating: 5 },
  { emoji: '🙂', rating: 4 },
  { emoji: '😐', rating: 3 },
  { emoji: '😕', rating: 2 },
  { emoji: '😡', rating: 1 }
];

const issues = [
  { key: 'need_improve_date', label: 'need improve date' },
  { key: 'hard_to_verify', label: 'hard to verify' },
  { key: 'too_broad', label: 'too broad' },
  { key: 'irrelevant', label: 'irrelevant' },
  { key: 'highly_predictable', label: 'highly predictable' }
];

function EventFilters({ filters, updateFilters, setPage, total }) {
  const clearFilters = () => {
    updateFilters('events', {
      reactions: [],
      issues: [],
      changes: [],
      has_comments: false,
      time_frame: null
    });
    setPage(1);
  };
      
  const handleFilterChange = (filterType, value) => {
    if (filterType === 'reactions') {
      const currentReactions = filters.events.reactions || [];
      const updatedReactions = currentReactions.includes(value)
        ? currentReactions.filter(r => r !== value)
        : [...currentReactions, value];
      
      updateFilters('events', { reactions: updatedReactions });
    } else if (filterType === 'issues') {
      const currentIssues = filters.events.issues || [];
      const updatedIssues = currentIssues.includes(value)
        ? currentIssues.filter(i => i !== value)
        : [...currentIssues, value];
      
      updateFilters('events', { issues: updatedIssues });
    } else if (filterType === 'changes') {
      const currentChanges = filters.events.changes || [];
      const updatedChanges = currentChanges.includes('changes')
        ? []
        : ['changes'];
      
      updateFilters('events', { changes: updatedChanges });
    } else if (filterType === 'has_comments') {
      const currentHasComments = filters.events.has_comments;
      const updatedHasComments = !currentHasComments;
      updateFilters('events', { has_comments: updatedHasComments });
    } else if (filterType === 'has_reasoning') {
      const currentHasReasoning = filters.events.has_reasoning;
      const updatedHasReasoning = !currentHasReasoning;
      updateFilters('events', { has_reasoning: updatedHasReasoning });
    } else if (filterType === 'time_frame') {
      const currentTimeFrame = filters.events.time_frame;
      const updatedTimeFrame = currentTimeFrame === value ? null : value;
      updateFilters('events', { time_frame: updatedTimeFrame });
    }
    setPage(1); // Reset to first page when filters change
  };

  return (
    <Box sx={{ mb: 3 }}>
      <Stack direction="row" spacing={0} alignItems="center" flexWrap="wrap" sx={{ mb: 2, gap: 1 }}>
        {/* Reactions Filter */}
        {reactions.map(({ emoji, rating }) => (
          <Button
            key={rating}
            variant={(filters.events.reactions || []).includes(rating) ? 'contained' : 'outlined'}
            size="small"
            onClick={() => handleFilterChange('reactions', rating)}
          >
            {emoji}
          </Button>
        ))}
        
        <Divider orientation="vertical" flexItem />
        
        {/* Issues Filter */}
        {issues.map(({ key, label }) => (
          <Button
            key={key}
            variant={(filters.events.issues || []).includes(key) ? 'contained' : 'outlined'}
            size="small"
            onClick={() => handleFilterChange('issues', key)}
          >
            {label}
          </Button>
        ))}
        
        <Divider orientation="vertical" flexItem />
        
        {/* Changes Filter */}
        <Button
          variant={(filters.events.changes || []).includes('changes') ? 'contained' : 'outlined'}
          size="small"
          onClick={() => handleFilterChange('changes', 'changes')}
        >
          Has Changes
        </Button>
        {/* Reasoning Filter */}
        <Button
          variant={filters.events.has_reasoning ? 'contained' : 'outlined'}
          size="small"
          onClick={() => handleFilterChange('has_reasoning')}
        >
          Has Reasoning
        </Button>

        {/* Comments Filter */}
        <Button
          variant={filters.events.has_comments ? 'contained' : 'outlined'}
          size="small"
          onClick={() => handleFilterChange('has_comments')}
        >
          Has Comments
        </Button>

        <Divider orientation="vertical" flexItem />

        {/* Time Frame Filters */}
        <Button
          variant={filters.events.time_frame === 'past' ? 'contained' : 'outlined'}
          size="small"
          onClick={() => handleFilterChange('time_frame', 'past')}
        >
          Past
        </Button>
        <Button
          variant={filters.events.time_frame === 'future' ? 'contained' : 'outlined'}
          size="small"
          onClick={() => handleFilterChange('time_frame', 'future')}
        >
          Future
        </Button>

        {(
          (filters.events.reactions && filters.events.reactions.length > 0) || 
          (filters.events.issues && filters.events.issues.length > 0) ||
          (filters.events.changes && filters.events.changes.length > 0) ||
          (filters.events.has_comments === true) ||
          (filters.events.time_frame !== null)
        ) && (
          <>
            <Divider orientation="vertical" flexItem />
            <Button
              variant="outlined"
              size="small"
              onClick={clearFilters}
            >
              Clear All Filters
            </Button>
          </>
        )}
      </Stack>

      <Typography variant="body2">
        Total: {total} events
      </Typography>
    </Box>
  );
}

export default EventFilters; 