import React from 'react';
import { Box, Stack, Button } from '@mui/material';

function EventActions({ events, selectedEvents }) {
  if (selectedEvents.length === 0) return null;

  const handleCopyJson = () => {
    const selectedEventData = events
      .filter(event => selectedEvents.includes(event.event_id))
      .map(event => {
        const { changes, feedback, parent_query_id, status, event_metadata, ...rest } = event;
        return rest;
      });
    navigator.clipboard.writeText(JSON.stringify(selectedEventData, null, 2));
  };

  const handleCopyPsql = () => {
    const selectedEventData = events
      .filter(event => selectedEvents.includes(event.event_id))
      .map(event => {
        const { event_id, external_id, cutoff, targeted_date, domain, answer, timestamp, title, description, country, resolved_at, price } = event;
        return `('${event_id}', '${external_id}', ${cutoff}, ${targeted_date}, ${domain}, ${answer}, ${timestamp}, '${title}', '${description}', '${country}', ${resolved_at}, '${price}')`;
      })
      .join(',\n');
    const psqlInsert = `INSERT INTO event (event_id, external_id, cutoff, targeted_date, domain, answer, timestamp, title, description, country, resolved_at, price) VALUES \n${selectedEventData};`;
    navigator.clipboard.writeText(psqlInsert);
  };

  return (
    <Box sx={{ mb: 3 }}>
      <Stack direction="row" spacing={1}>
        <Button
          variant="outlined"
          size="small"
          onClick={handleCopyJson}
        >
          Copy as JSON ({selectedEvents.length})
        </Button>
        <Button
          variant="outlined"
          size="small"
          onClick={handleCopyPsql}
        >
          Copy as PSQL ({selectedEvents.length})
        </Button>
      </Stack>
    </Box>
  );
}

export default EventActions;
